// import React, { useState, useEffect } from 'react';
// import { useParams, useLocation } from 'react-router-dom';
// import { supabase } from './supabaseClient';
// import './Messaging.css';
// import ChatView from './ChatView';

// const Messaging = () => {
//   const { userId } = useParams(); // The seller's user ID from the URL
//   const location = useLocation();
//   const currentUserId = 'b8bc084a-4ebe-4621-9502-6f646e537863'; // Replace with the current logged-in user's ID
//   const [selectedChat, setSelectedChat] = useState(null);
//   const [chats, setChats] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');

//   useEffect(() => {
//     const fetchChats = async () => {
//       const { data, error } = await supabase
//         .from('messages')
//         .select('*')
//         .or(`sender_id.eq.${currentUserId},receiver_id.eq.${currentUserId}`)
//         .order('timestamp', { ascending: false });

//       if (error) {
//         console.error('Error fetching chats:', error);
//       } else {
//         const uniqueChats = {};

//         data.forEach(message => {
//           const chatPartnerId = message.sender_id === currentUserId ? message.receiver_id : message.sender_id;

//           if (chatPartnerId === currentUserId) {
//             return;
//           }

//           if (!uniqueChats[chatPartnerId] || new Date(message.timestamp) > new Date(uniqueChats[chatPartnerId].timestamp)) {
//             uniqueChats[chatPartnerId] = {
//               id: chatPartnerId,
//               name: 'Chat Partner',
//               message: message.message,
//               time: new Date(message.timestamp).toLocaleString(),
//               imgSrc: 'https://via.placeholder.com/40',
//               timestamp: message.timestamp,
//             };
//           }
//         });

//         const chatPartnerIds = Object.keys(uniqueChats);
//         const { data: usersData, error: usersError } = await supabase
//           .from('users')
//           .select('id, full_name, profile_picture')
//           .in('id', chatPartnerIds);

//         if (usersError) {
//           console.error('Error fetching user info:', usersError);
//         } else {
//           usersData.forEach(user => {
//             if (uniqueChats[user.id]) {
//               uniqueChats[user.id].name = user.full_name;
//               uniqueChats[user.id].imgSrc = user.profile_picture || 'https://via.placeholder.com/40';
//             }
//           });

//           setChats(Object.values(uniqueChats));
//         }
//       }
//     };

//     const fetchSellerInfo = async () => {
//       if (!userId) return;
//       const { data, error } = await supabase
//         .from('users')
//         .select('*')
//         .eq('id', userId)
//         .single();

//       if (error) {
//         console.error('Error fetching seller info:', error);
//       } else {
//         const initialMessage = location.state?.initialMessage || '';
//         setSelectedChat({
//           id: userId,
//           name: data.full_name,
//           imgSrc: data.profile_picture || 'https://via.placeholder.com/40',
//           message: initialMessage,
//         });
//       }
//     };

//     fetchChats();
//     fetchSellerInfo();
//   }, [currentUserId, userId, location.state]);

//   const handleChatClick = (chat) => {
//     setSelectedChat(chat);
//   };

//   const updateLastMessageLocally = (chatId, message) => {
//     setChats((prevChats) =>
//       prevChats.map((chat) =>
//         chat.id === chatId
//           ? { ...chat, message: message.message, time: message.time }
//           : chat
//       )
//     );
//   };

//   const filteredChats = chats.filter(chat =>
//     chat.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     chat.message.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <div className="messaging-container">
//       <aside className="chat-sidebar">
//         <h2>Chats</h2>
//         <input
//           type="text"
//           placeholder="Search Messenger"
//           className="search-bar"
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//         />
//         <div className="chat-list">
//           {filteredChats.map(chat => (
//             <div
//               key={chat.id}
//               className={`chat-item ${selectedChat && selectedChat.id === chat.id ? 'selected' : ''}`}
//               onClick={() => handleChatClick(chat)}
//             >
//               <img src={chat.imgSrc} alt={chat.name} className="chat-item-img" />
//               <div className="chat-item-content">
//                 <p className="chat-item-name">{chat.name}</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </aside>

//       {selectedChat ? (
//         <ChatView
//           selectedChat={selectedChat}
//           currentUserId={currentUserId}
//           updateLastMessageLocally={updateLastMessageLocally}
//         />
//       ) : (
//         <main className="message-window">
//           <p className="no-chat-selected">Select a chat to view messages</p>
//         </main>
//       )}
//     </div>
//   );
// };

// export default Messaging;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from './supabaseClient';
import './Messaging.css';
import ChatView from './ChatView';

const Messaging = () => {
  const { userId } = useParams(); // The seller's user ID from the URL
  // const location = useLocation();
  const currentUserId = localStorage.getItem('userId');
  // const currentUserId = 'b8bc084a-4ebe-4621-9502-6f646e537863'; // Replace with the current logged-in user's ID
  const [selectedChat, setSelectedChat] = useState(null);
  const [chats, setChats] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchChats = async () => {
      const { data, error } = await supabase
        .from('messages')
        .select('*')
        .or(`sender_id.eq.${currentUserId},receiver_id.eq.${currentUserId}`)
        .order('timestamp', { ascending: false });

      if (error) {
        console.error('Error fetching chats:', error);
      } else {
        const uniqueChats = {};

        data.forEach(message => {
          const chatPartnerId = message.sender_id === currentUserId ? message.receiver_id : message.sender_id;

          if (chatPartnerId === currentUserId) {
            return;
          }

          if (!uniqueChats[chatPartnerId] || new Date(message.timestamp) > new Date(uniqueChats[chatPartnerId].timestamp)) {
            uniqueChats[chatPartnerId] = {
              id: chatPartnerId,
              name: 'Chat Partner',
              message: message.message,
              time: new Date(message.timestamp).toLocaleString(),
              imgSrc: 'https://via.placeholder.com/40',
              timestamp: message.timestamp,
            };
          }
        });

        const chatPartnerIds = Object.keys(uniqueChats);
        const { data: usersData, error: usersError } = await supabase
          .from('users')
          .select('id, full_name, profile_picture')
          .in('id', chatPartnerIds);

        if (usersError) {
          console.error('Error fetching user info:', usersError);
        } else {
          usersData.forEach(user => {
            if (uniqueChats[user.id]) {
              uniqueChats[user.id].name = user.full_name;
              uniqueChats[user.id].imgSrc = user.profile_picture || 'https://via.placeholder.com/40';
            }
          });

          setChats(Object.values(uniqueChats));

          // Automatically select the chat if we navigated with a userId (e.g., from "Message Seller" button)
          if (userId) {
            const selectedChat = Object.values(uniqueChats).find(chat => chat.id === userId);
            if (selectedChat) {
              setSelectedChat(selectedChat);
            }
          }
        }
      }
    };

    fetchChats();
  }, [currentUserId, userId]);

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
  };

  const updateLastMessageLocally = (chatId, message) => {
    setChats((prevChats) =>
      prevChats.map((chat) =>
        chat.id === chatId
          ? { ...chat, message: message.message, time: message.time }
          : chat
      )
    );
  };

  const filteredChats = chats.filter(chat =>
    chat.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    chat.message.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="messaging-container">
      <aside className="chat-sidebar">
        <h2>Chats</h2>
        <input
          type="text"
          placeholder="Search Messenger"
          className="search-bar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="chat-list">
          {filteredChats.map(chat => (
            <div
              key={chat.id}
              className={`chat-item ${selectedChat && selectedChat.id === chat.id ? 'selected' : ''}`}
              onClick={() => handleChatClick(chat)}
            >
              <img src={chat.imgSrc} alt={chat.name} className="chat-item-img" />
              <div className="chat-item-content">
                <p className="chat-item-name">{chat.name}</p>
              </div>
            </div>
          ))}
        </div>
      </aside>

      {selectedChat ? (
        <ChatView
          selectedChat={selectedChat}
          currentUserId={currentUserId}
          updateLastMessageLocally={updateLastMessageLocally}
        />
      ) : (
        <main className="message-window">
          <p className="no-chat-selected">Select a chat to view messages</p>
        </main>
      )}
    </div>
  );
};

export default Messaging;
