// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { supabase } from './supabaseClient';
// import './Signup.css';

// const Signup = () => {
//   const [formData, setFormData] = useState({ email: '', password: '' });
//   const [error, setError] = useState(null);
//   const [confirmationSent, setConfirmationSent] = useState(false);
//   const [checkingConfirmation, setCheckingConfirmation] = useState(false);
//   const navigate = useNavigate();

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSignup = async (e) => {
//     e.preventDefault();
//     setError(null);

//     // Send signup request
//     const { data, error } = await supabase.auth.signUp({
//       email: formData.email,
//       password: formData.password,
//     });

//     if (error) {
//       setError(error.message);
//     } else {
//       localStorage.setItem('userEmail', formData.email); // Store user email for future steps
//       setConfirmationSent(true); // Mark that confirmation email has been sent
//       checkEmailConfirmation(data.user.id); // Start checking email confirmation
//     }
//   };

//   // Function to periodically check if the email has been confirmed
//   const checkEmailConfirmation = (userId) => {
//     setCheckingConfirmation(true);
//     const interval = setInterval(async () => {
//         const { data } = await supabase.auth.getUser();
//         if (data && data.user.email_confirmed_at) {
//         clearInterval(interval); // Stop checking once confirmed
//         localStorage.setItem('userId', userId); // Store userId for future steps
//         setCheckingConfirmation(false);
//         navigate('/account-setup'); // Navigate to account setup after confirmation
//       }
//     }, 3000); // Check every 3 seconds
//   };

//   return (
//     <div className="signup-container">
//       <h1>Signup</h1>
//       <form onSubmit={handleSignup}>
//         <div className="form-group">
//           <label>Email</label>
//           <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleInputChange}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Password</label>
//           <input
//             type="password"
//             name="password"
//             value={formData.password}
//             onChange={handleInputChange}
//             required
//           />
//         </div>
//         {error && <p className="error-message">{error}</p>}
//         {!confirmationSent ? (
//           <button type="submit">Sign Up</button>
//         ) : (
//           <p>Check your email to confirm your account. We are waiting for confirmation...</p>
//         )}
//       </form>
//       {checkingConfirmation && (
//         <p>Checking for email confirmation...</p>
//       )}
//     </div>
//   );
// };

// export default Signup;

import React, { useState } from 'react';
import { supabase } from './supabaseClient';
import './Signup.css';

const Signup = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);
  const [confirmationSent, setConfirmationSent] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setError(null);

    const { error } = await supabase.auth.signUp({
      email: formData.email,
      password: formData.password,
      options: {
        emailRedirectTo: `http://herdmate.app/account-setup`, // Set the redirect to account-setup page
      },
    });

    if (error) {
      setError(error.message);
    } else {
      localStorage.setItem('userEmail', formData.email); // Store user email for future steps
      setConfirmationSent(true); // Confirmation email sent
    }
  };

  return (
    <div className="signup-container signupBackground">
      <div class="signupCards">
        <h1 class="signupFontStuffIDFK">Signup</h1>
        <form onSubmit={handleSignup}>
          <div className="form-group">
            <label>Email</label>
            <input
              class="text-bg-stuf"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              class="text-bg-stuf"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          {!confirmationSent ? (
            <button type="submit" class="signupButton">Sign Up</button>
          ) : (
            <p>Check your email to confirm your account.</p>
          )}
        </form>
      </div>
    </div>
  );
};

export default Signup;
