// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import AWS from 'aws-sdk';
// import { supabase } from './supabaseClient';
// import './AccountSetup.css';

// const AccountSetup = ({ setIsSignedIn }) => {
//   localStorage.setItem('userId', null); // Store the user ID in localStorage
//   const [profile, setProfile] = useState({
//     profilePicture: null,
//     fullName: '',
//     email: '',
//     phone: '',
//     farmName: '',
//     farmWebsite: '',
//     address: '',
//     state: '',
//     city: '',
//     zipcode: '',
//     password: '',
//   });
//   const [isProfileComplete, setIsProfileComplete] = useState(false);
//   const navigate = useNavigate();

//   // AWS S3 configuration
//   const s3 = new AWS.S3({
//     accessKeyId: 'AKIAQWHCP2VJBRYY5YUW',
//     secretAccessKey: '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',
//     region: 'us-east-2',
//   });

//   const handleProfileChange = (e) => {
//     setProfile({ ...profile, [e.target.name]: e.target.value });
//   };

//   const handleFileChange = (e) => {
//     setProfile({ ...profile, profilePicture: e.target.files[0] });
//   };

//   const handleProfileSubmit = async (e) => {
//     e.preventDefault();

//     let profilePictureUrl = null;

//     // Upload profile picture to S3
//     if (profile.profilePicture) {
//       const uploadParams = {
//         Bucket: 'cowcum',
//         Key: `${Date.now()}_${profile.profilePicture.name}`,
//         Body: profile.profilePicture,
//         ContentType: profile.profilePicture.type,
//       };

//       try {
//         const uploadResult = await s3.upload(uploadParams).promise();
//         profilePictureUrl = uploadResult.Location;
//       } catch (err) {
//         console.error('Error uploading profile picture to S3:', err);
//         return;
//       }
//     }

//     // Save user profile data to Supabase
//     const { error } = await supabase
//       .from('users')
//       .insert({
//         id: localStorage.getItem('userId'),
//         profile_picture: profilePictureUrl,
//         full_name: profile.fullName,
//         email: localStorage.getItem('userEmail'),
//         phone_number: profile.phone,
//         farm_name: profile.farmName,
//         farm_website: profile.farmWebsite,
//         address: profile.address,
//         State: profile.state,
//         City: profile.city,
//         zipcode: profile.zipcode,
//       });

//     if (!error) {
//       setIsProfileComplete(true);

//       // Fetch the user ID after saving the profile
//       const { data: userData, error: userError } = await supabase
//         .from('users')
//         .select('id')
//         .eq('email', profile.email)
//         .single();

//       if (userError) {
//         console.error('Error fetching user ID:', userError);
//         return;
//       }

//       localStorage.setItem('userId', userData.id)
//       // Navigate to the credit card setup page, passing the user ID
//       navigate(`/credit-card-setup/${userData.id}`);
//     } else {
//       alert('Error saving profile');
//     }
//   };

//   return (
//     <div className="account-setup-container">
//       {!isProfileComplete ? (
//         <form onSubmit={handleProfileSubmit}>
//           <h1>Complete Your Profile</h1>
//           <div className="form-group">
//             <label>Profile Picture</label>
//             <input type="file" name="profilePicture" onChange={handleFileChange} />
//           </div>
//           <div className="form-group">
//             <label>Full Name</label>
//             <input type="text" name="fullName" value={profile.fullName} onChange={handleProfileChange} required />
//           </div>
//           <div className="form-group">
//             <label>Phone Number</label>
//             <input type="tel" name="phone" value={profile.phone} onChange={handleProfileChange} required />
//           </div>
//           <div className="form-group">
//             <label>Farm Name</label>
//             <input type="text" name="farmName" value={profile.farmName} onChange={handleProfileChange} required />
//           </div>
//           <div className="form-group">
//             <label>Farm Website</label>
//             <input type="text" name="farmWebsite" value={profile.farmWebsite} onChange={handleProfileChange} />
//           </div>
//           <div className="form-group">
//             <label>Address</label>
//             <input type="text" name="address" value={profile.address} onChange={handleProfileChange} required />
//           </div>
//           <div className="form-group">
//             <label>State</label>
//             <input type="text" name="state" value={profile.state} onChange={handleProfileChange} required />
//           </div>
//           <div className="form-group">
//             <label>City</label>
//             <input type="text" name="city" value={profile.city} onChange={handleProfileChange} required />
//           </div>
//           <div className="form-group">
//             <label>Zipcode</label>
//             <input type="text" name="zipcode" value={profile.zipcode} onChange={handleProfileChange} required />
//           </div>
//           <button type="submit" className="submit-button">Save Profile</button>
//         </form>
//       ) : (
//         <div className="product-setup">
//           <h1>Add Products</h1>
//           <button className="complete-setup-button" onClick={() => navigate('/credit-card-setup/:userId')}>Add Products</button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AccountSetup;


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import { supabase } from './supabaseClient';
import './AccountSetup.css';

const AccountSetup = ({ setIsSignedIn }) => {
  const userId = localStorage.getItem('userId'); // Retrieve user ID from localStorage
  const [profile, setProfile] = useState({
    profilePicture: null,
    fullName: '',
    phone: '',
    farmName: '',
    farmWebsite: '',
    address: '',
    state: '',
    city: '',
    zipcode: '',
  });
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const navigate = useNavigate();

  // AWS S3 configuration for uploading the profile picture
  const s3 = new AWS.S3({
    accessKeyId: 'AKIAQWHCP2VJBRYY5YUW',
    secretAccessKey: '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',
    region: 'us-east-2',
  });

  const handleProfileChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setProfile({ ...profile, profilePicture: e.target.files[0] });
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();

    let profilePictureUrl = null;

    // Upload profile picture to S3
    if (profile.profilePicture) {
      const uploadParams = {
        Bucket: 'cowcum',
        Key: `${Date.now()}_${profile.profilePicture.name}`,
        Body: profile.profilePicture,
        ContentType: profile.profilePicture.type,
      };

      try {
        const uploadResult = await s3.upload(uploadParams).promise();
        profilePictureUrl = uploadResult.Location;
      } catch (err) {
        console.error('Error uploading profile picture to S3:', err);
        return;
      }
    }

    // Save user profile data to Supabase
    const { error } = await supabase
      .from('users')
      .update({
        profile_picture: profilePictureUrl,
        full_name: profile.fullName,
        phone_number: profile.phone,
        farm_name: profile.farmName,
        farm_website: profile.farmWebsite,
        address: profile.address,
        State: profile.state,
        City: profile.city,
        zipcode: profile.zipcode,
      })
      .eq('id', userId);

    if (!error) {
      setIsProfileComplete(true);
      navigate(`/credit-card-setup/${userId}`); // Redirect to credit card setup page
    } else {
      alert('Error saving profile');
    }
  };

  return (
    <div className="account-setup-container">
      {!isProfileComplete ? (
        <form onSubmit={handleProfileSubmit}>
          <h1>Complete Your Profile</h1>
          <div className="form-group">
            <label>Profile Picture</label>
            <input type="file" name="profilePicture" onChange={handleFileChange} />
          </div>
          <div className="form-group">
            <label>Full Name</label>
            <input type="text" name="fullName" value={profile.fullName} onChange={handleProfileChange} required />
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <input type="tel" name="phone" value={profile.phone} onChange={handleProfileChange} required />
          </div>
          <div className="form-group">
            <label>Farm Name</label>
            <input type="text" name="farmName" value={profile.farmName} onChange={handleProfileChange} required />
          </div>
          <div className="form-group">
            <label>Farm Website</label>
            <input type="text" name="farmWebsite" value={profile.farmWebsite} onChange={handleProfileChange} />
          </div>
          <div className="form-group">
            <label>Address</label>
            <input type="text" name="address" value={profile.address} onChange={handleProfileChange} required />
          </div>
          <div className="form-group">
            <label>State</label>
            <input type="text" name="state" value={profile.state} onChange={handleProfileChange} required />
          </div>
          <div className="form-group">
            <label>City</label>
            <input type="text" name="city" value={profile.city} onChange={handleProfileChange} required />
          </div>
          <div className="form-group">
            <label>Zipcode</label>
            <input type="text" name="zipcode" value={profile.zipcode} onChange={handleProfileChange} required />
          </div>
          <button type="submit" className="submit-button">Save Profile</button>
        </form>
      ) : (
        <div className="product-setup">
          <h1>Add Products</h1>
          <button className="complete-setup-button" onClick={() => navigate(`/credit-card-setup/${userId}`)}>Next</button>
        </div>
      )}
    </div>
  );
};

export default AccountSetup;
