import React, { useState, useEffect, useCallback } from 'react';
import './Shop.css';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';


const Shop = () => {
  const navigate = useNavigate();
  // const userId = 'b8bc084a-4ebe-4621-9502-6f646e537863';
  const userId = localStorage.getItem('userId'); // Retrieve user ID from localStorage

  const [selectedProductType, setSelectedProductType] = useState('sires');
  const [filters, setFilters] = useState({});
  const [products, setProducts] = useState([]);
  const [savedItems, setSavedItems] = useState([]);

  const fetchProducts = useCallback(async () => {
    let { data, error } = await supabase
      .from(selectedProductType)
      .select('*')
      .neq('user_id', userId);

    if (error) console.error('Error fetching products:', error);
    else setProducts(data);
  }, [selectedProductType, userId]);

  const [selectedFeatures, setSelectedFeatures] = useState({
    sires: ['bull_name', 'breed', 'state', 'price'], // Default features for sires
    dams: ['bull_name', 'breed', 'state', 'price'], // Default features for dams
  });



  const availableFeatures = {
    sires: ['bull_name', 'breed', 'state', 'price', 'calving_ease_direct', 'birth_weight', 'weaning_weight', 'yearling_weight'],
    dams: ['bull_name', 'breed', 'state', 'price', 'milk_yield', 'calving_interval', 'calving_ease_dir', 'birth_weight'],
  };

  const handleFeatureChange = (productType, feature) => {
    setSelectedFeatures((prevSelectedFeatures) => ({
      ...prevSelectedFeatures,
      [productType]: prevSelectedFeatures[productType].includes(feature)
        ? prevSelectedFeatures[productType].filter(f => f !== feature)
        : [...prevSelectedFeatures[productType], feature],
    }));
  };


  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const applyFilters = async () => {
    let query = supabase.from(selectedProductType).select('*').neq('user_id', userId);

    if (filters.breed) query = query.ilike('breed', `%${filters.breed}%`);
    if (filters.price_min && filters.price_max) query = query.gte('price', filters.price_min).lte('price', filters.price_max);
    if (filters.born_min && filters.born_max) {
      const currentYear = new Date().getFullYear();
      query = query.gte('born', `${currentYear - filters.born_max}-01-01`).lte('born', `${currentYear - filters.born_min}-12-31`);
    }
    if (filters.state) query = query.eq('state', filters.state);
    if (filters.calving_ease_direct_min && filters.calving_ease_direct_max) query = query.gte('calving_ease_direct', filters.calving_ease_direct_min).lte('calving_ease_direct', filters.calving_ease_direct_max);
    if (filters.birth_weight_min && filters.birth_weight_max) query = query.gte('birth_weight', filters.birth_weight_min).lte('birth_weight', filters.birth_weight_max);
    if (filters.weaning_weight_min && filters.weaning_weight_max) query = query.gte('weaning_weight', filters.weaning_weight_min).lte('weaning_weight', filters.weaning_weight_max);
    if (filters.yearling_weight_min && filters.yearling_weight_max) query = query.gte('yearling_weight', filters.yearling_weight_min).lte('yearling_weight', filters.yearling_weight_max);
    if (filters.dry_matter_intake_min && filters.dry_matter_intake_max) query = query.gte('dry_matter_intake', filters.dry_matter_intake_min).lte('dry_matter_intake', filters.dry_matter_intake_max);
    if (filters.yearling_height_min && filters.yearling_height_max) query = query.gte('yearling_height', filters.yearling_height_min).lte('yearling_height', filters.yearling_height_max);
    if (filters.scrotal_circumference_min && filters.scrotal_circumference_max) query = query.gte('scrotal_circumference', filters.scrotal_circumference_min).lte('scrotal_circumference', filters.scrotal_circumference_max);
    if (filters.docility_min && filters.docility_max) query = query.gte('docility', filters.docility_min).lte('docility', filters.docility_max);
    if (filters.claw_angle_min && filters.claw_angle_max) query = query.gte('claw_angle', filters.claw_angle_min).lte('claw_angle', filters.claw_angle_max);
    if (filters.foot_angle_min && filters.foot_angle_max) query = query.gte('foot_angle', filters.foot_angle_min).lte('foot_angle', filters.foot_angle_max);
    if (filters.heifer_pregnancy_min && filters.heifer_pregnancy_max) query = query.gte('heifer_pregnancy', filters.heifer_pregnancy_min).lte('heifer_pregnancy', filters.heifer_pregnancy_max);
    if (filters.calving_ease_maternal_min && filters.calving_ease_maternal_max) query = query.gte('calving_ease_maternal', filters.calving_ease_maternal_min).lte('calving_ease_maternal', filters.calving_ease_maternal_max);
    if (filters.milk_min && filters.milk_max) query = query.gte('milk', filters.milk_min).lte('milk', filters.milk_max);
    if (filters.mature_weight_min && filters.mature_weight_max) query = query.gte('mature_weight', filters.mature_weight_min).lte('mature_weight', filters.mature_weight_max);
    if (filters.mature_height_min && filters.mature_height_max) query = query.gte('mature_height', filters.mature_height_min).lte('mature_height', filters.mature_height_max);
    if (filters.carcass_weight_min && filters.carcass_weight_max) query = query.gte('carcass_weight', filters.carcass_weight_min).lte('carcass_weight', filters.carcass_weight_max);
    if (filters.carcass_marbling_min && filters.carcass_marbling_max) query = query.gte('carcass_marbling', filters.carcass_marbling_min).lte('carcass_marbling', filters.carcass_marbling_max);
    if (filters.carcass_ribeye_min && filters.carcass_ribeye_max) query = query.gte('carcass_ribeye', filters.carcass_ribeye_min).lte('carcass_ribeye', filters.carcass_ribeye_max);
    if (filters.carcass_fat_min && filters.carcass_fat_max) query = query.gte('carcass_fat', filters.carcass_fat_min).lte('carcass_fat', filters.carcass_fat_max);
    if (filters.f11) query = query.eq('f11', filters.f11);
    if (filters.f13) query = query.eq('f13', filters.f13);
    if (filters.chs) query = query.eq('chs', filters.chs);
    if (filters.band3) query = query.eq('band3', filters.band3);
    if (filters.color) query = query.eq('color', filters.color);
    if (filters.horn_status) query = query.eq('horn_status', filters.horn_status);
    if (filters.verified) query = query.eq('verified', filters.verified);

    const { data, error } = await query;

    if (error) console.error('Error applying filters:', error);
    else setProducts(data);
  };

  const resetFilters = () => {
    setFilters({});
    fetchProducts();
  };

  const handleRadioChange = (value) => {
    setSelectedProductType(value);
    setFilters({});
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  useEffect(() => {
    const fetchSavedItems = async () => {
      const { data, error } = await supabase
        .from('saved_listings')
        .select('*')
        .eq('user_id', userId); // Replace with actual user ID

      if (error) {
        console.error('Error fetching saved items:', error);
      } else {
        setSavedItems(data);
      }
    };

    fetchProducts();
    fetchSavedItems(); // Fetch saved items when the component mounts
  }, [fetchProducts]);

  const toggleSaveItem = async (productId) => {
    const productTypeKey = selectedProductType === 'sires' ? 'sire_id' : 'dam_id';

    const existingItem = savedItems.find((item) => item[productTypeKey] === productId);

    if (existingItem) {
      // If already saved, remove it
      await supabase
        .from('saved_listings')
        .delete()
        .eq('id', existingItem.id);

      setSavedItems(savedItems.filter((item) => item[productTypeKey] !== productId));
    } else {
      // If not saved, add it
      const { data, error } = await supabase
        .from('saved_listings')
        .insert({
          user_id: userId, // Replace with actual user ID
          [productTypeKey]: productId,
        })
        .select();

      if (error) {
        console.error('Error saving item:', error);
      } else {
        setSavedItems([...savedItems, data[0]]);
      }
    }
  };

  const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth > 400);

  const handleResize = () => {
    if (window.innerWidth <= 400) {
      setIsSidebarVisible(false);
    } else {
      setIsSidebarVisible(true);
    }
  };

  useEffect(() => {
    const sidebar = document.querySelector('.newSidebar');
    if (sidebar) {
      if (isSidebarVisible) {
        sidebar.style.transform = 'translateX(62vw)';
      } else {
        sidebar.style.transform = 'translateX(0)';
      }
    }
  }, [isSidebarVisible]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className="shop-container">
      {window.innerWidth <= 400 && (
        <button className="sidebar-toggle newSidebar" onClick={() => setIsSidebarVisible(!isSidebarVisible)}>
          {isSidebarVisible ? '←' : '→'}
        </button>
      )}
      {isSidebarVisible && (
        <aside className="sidebar">
          <div class="containerOutline">
            <h2 class="sideBarFont">Product Types</h2>
            <div>
              <label>
                <input
                  type="radio"
                  value="sires"
                  onChange={() => handleRadioChange('sires')}
                  checked={selectedProductType === 'sires'}
                />
                Bull Semen (Sires)
              </label>
              <label>
                <input
                  type="radio"
                  value="dams"
                  onChange={() => handleRadioChange('dams')}
                  checked={selectedProductType === 'dams'}
                />
                Cow Embryos (Dams)
              </label>
            </div>
          </div>

          <div class="containerOutline newSpec">
            <div className="feature-selection">
              <h3 class="sideBarFont newSize">Select Features to Display</h3>
              {availableFeatures[selectedProductType].map((feature) => (
                <label key={feature}>
                  <input
                    type="checkbox"
                    checked={selectedFeatures[selectedProductType].includes(feature)}
                    onChange={() => handleFeatureChange(selectedProductType, feature)}
                  />
                  {feature.replace(/_/g, ' ')} {/* Replace underscores with spaces for readability */}
                </label>
              ))}
            </div>
          </div>

          <div className="containerOutline filters newSpec">
            <h3 class="sideBarFont">Filters</h3>

            <label>Breed</label>
            <select
              name="breed"
              value={filters.breed || ''}
              onChange={handleFilterChange}
            >
              <option value="">All Breeds</option>
              <option value="Angus">Angus</option>
              <option value="Hereford">Hereford</option>
              <option value="Charolais">Charolais</option>
              <option value="Simmental">Simmental</option>
              <option value="Limousin">Limousin</option>
              <option value="Brahman">Brahman</option>
              <option value="Shorthorn">Shorthorn</option>
              <option value="Gelbvieh">Gelbvieh</option>
              <option value="Brangus">Brangus</option>
              <option value="Red Angus">Red Angus</option>
              <option value="Beefmaster">Beefmaster</option>
              <option value="Santa Gertrudis">Santa Gertrudis</option>
              <option value="Maine-Anjou">Maine-Anjou</option>
              <option value="Chianina">Chianina</option>
              <option value="Salers">Salers</option>
              <option value="Texas Longhorn">Texas Longhorn</option>
              <option value="Wagyu">Wagyu</option>
              <option value="Piedmontese">Piedmontese</option>
              <option value="Belgian Blue">Belgian Blue</option>
              <option value="Devon">Devon</option>
              <option value="Galloway">Galloway</option>
              <option value="Highland">Highland</option>
              <option value="Murray Grey">Murray Grey</option>
              <option value="Nellore">Nellore</option>
              <option value="Romagnola">Romagnola</option>
              <option value="Tarentaise">Tarentaise</option>
              <option value="Blonde d'Aquitaine">Blonde d'Aquitaine</option>
              <option value="South Devon">South Devon</option>
              <option value="Pinzgauer">Pinzgauer</option>
              <option value="Senepol">Senepol</option>
              <option value="Afrikaner">Afrikaner</option>
              <option value="Ankole-Watusi">Ankole-Watusi</option>
              <option value="Braford">Braford</option>
              <option value="Bison">Bison</option>
              <option value="Boran">Boran</option>
              <option value="Marchigiana">Marchigiana</option>
              <option value="Santa Cruz">Santa Cruz</option>
              <option value="Sussex">Sussex</option>
              <option value="Tuli">Tuli</option>
              <option value="Bonsmara">Bonsmara</option>
            </select>

            <label>State</label>
            <select
              name="state"
              value={filters.state || ''}
              onChange={handleFilterChange}
            >
              <option value="">All States</option>
              <option value="Alabama">Alabama</option>
              <option value="Alaska">Alaska</option>
              <option value="Arizona">Arizona</option>
              <option value="Arkansas">Arkansas</option>
              <option value="California">California</option>
              <option value="Colorado">Colorado</option>
              <option value="Connecticut">Connecticut</option>
              <option value="Delaware">Delaware</option>
              <option value="Florida">Florida</option>
              <option value="Georgia">Georgia</option>
              <option value="Hawaii">Hawaii</option>
              <option value="Idaho">Idaho</option>
              <option value="Illinois">Illinois</option>
              <option value="Indiana">Indiana</option>
              <option value="Iowa">Iowa</option>
              <option value="Kansas">Kansas</option>
              <option value="Kentucky">Kentucky</option>
              <option value="Louisiana">Louisiana</option>
              <option value="Maine">Maine</option>
              <option value="Maryland">Maryland</option>
              <option value="Massachusetts">Massachusetts</option>
              <option value="Michigan">Michigan</option>
              <option value="Minnesota">Minnesota</option>
              <option value="Mississippi">Mississippi</option>
              <option value="Missouri">Missouri</option>
              <option value="Montana">Montana</option>
              <option value="Nebraska">Nebraska</option>
              <option value="Nevada">Nevada</option>
              <option value="New Hampshire">New Hampshire</option>
              <option value="New Jersey">New Jersey</option>
              <option value="New Mexico">New Mexico</option>
              <option value="New York">New York</option>
              <option value="North Carolina">North Carolina</option>
              <option value="North Dakota">North Dakota</option>
              <option value="Ohio">Ohio</option>
              <option value="Oklahoma">Oklahoma</option>
              <option value="Oregon">Oregon</option>
              <option value="Pennsylvania">Pennsylvania</option>
              <option value="Rhode Island">Rhode Island</option>
              <option value="South Carolina">South Carolina</option>
              <option value="South Dakota">South Dakota</option>
              <option value="Tennessee">Tennessee</option>
              <option value="Texas">Texas</option>
              <option value="Utah">Utah</option>
              <option value="Vermont">Vermont</option>
              <option value="Virginia">Virginia</option>
              <option value="Washington">Washington</option>
              <option value="West Virginia">West Virginia</option>
              <option value="Wisconsin">Wisconsin</option>
              <option value="Wyoming">Wyoming</option>
            </select>

            <label>Price Range</label>
            <input
              class="inputBox"
              type="number"
              name="price_min"
              value={filters.price_min || ''}
              onChange={handleFilterChange}
              placeholder="Min"
            />
            <input
              class="inputBox"
              type="number"
              name="price_max"
              value={filters.price_max || ''}
              onChange={handleFilterChange}
              placeholder="Max"
            />

            <label>Born (Age Range)</label>
            <input
              class="inputBox"
              type="number"
              name="born_min"
              value={filters.born_min || ''}
              onChange={handleFilterChange}
              placeholder="Min Age"
            />
            <input
              class="inputBox"
              type="number"
              name="born_max"
              value={filters.born_max || ''}
              onChange={handleFilterChange}
              placeholder="Max Age"
            />

            {selectedProductType === 'sires' && (
              <>
                <label>Calving Ease Direct (CED)</label>
                <input
                  class="inputBox"
                  type="number"
                  name="calving_ease_direct_min"
                  value={filters.calving_ease_direct_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min %"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="calving_ease_direct_max"
                  value={filters.calving_ease_direct_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max %"
                />

                <label>Birth Weight</label>
                <input
                  class="inputBox"
                  type="number"
                  name="birth_weight_min"
                  value={filters.birth_weight_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min lbs"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="birth_weight_max"
                  value={filters.birth_weight_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max lbs"
                />

                <label>Weaning Weight</label>
                <input
                  class="inputBox"
                  type="number"
                  name="weaning_weight_min"
                  value={filters.weaning_weight_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min lbs"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="weaning_weight_max"
                  value={filters.weaning_weight_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max lbs"
                />

                <label>Yearling Weight</label>
                <input
                  class="inputBox"
                  type="number"
                  name="yearling_weight_min"
                  value={filters.yearling_weight_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min lbs"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="yearling_weight_max"
                  value={filters.yearling_weight_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max lbs"
                />

                <label>Dry Matter Intake</label>
                <input
                  class="inputBox"
                  type="number"
                  name="dry_matter_intake_min"
                  value={filters.dry_matter_intake_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min lbs/day"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="dry_matter_intake_max"
                  value={filters.dry_matter_intake_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max lbs/day"
                />

                <label>Yearling Height</label>
                <input
                  class="inputBox"
                  type="number"
                  name="yearling_height_min"
                  value={filters.yearling_height_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min inches"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="yearling_height_max"
                  value={filters.yearling_height_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max inches"
                />

                <label>Scrotal Circumference</label>
                <input
                  class="inputBox"
                  type="number"
                  name="scrotal_circumference_min"
                  value={filters.scrotal_circumference_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min cm"
                />
                <input  
                  class="inputBox"
                  type="number"
                  name="scrotal_circumference_max"
                  value={filters.scrotal_circumference_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max cm"
                />

                <label>Docility</label>
                <input
                  class="inputBox"
                  type="number"
                  name="docility_min"
                  value={filters.docility_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="docility_max"
                  value={filters.docility_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max"
                />

                <label>Claw Angle</label>
                <input
                  class="inputBox"
                  type="number"
                  name="claw_angle_min"
                  value={filters.claw_angle_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="claw_angle_max"
                  value={filters.claw_angle_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max"
                />

                <label>Foot Angle</label>
                <input
                  class="inputBox"
                  type="number"
                  name="foot_angle_min"
                  value={filters.foot_angle_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="foot_angle_max"
                  value={filters.foot_angle_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max"
                />

                <label>Heifer Pregnancy</label>
                <input
                  class="inputBox"
                  type="number"
                  name="heifer_pregnancy_min"
                  value={filters.heifer_pregnancy_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min %"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="heifer_pregnancy_max"
                  value={filters.heifer_pregnancy_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max %"
                />

                <label>Calving Ease Maternal (CEM)</label>
                <input
                  class="inputBox"
                  type="number"
                  name="calving_ease_maternal_min"
                  value={filters.calving_ease_maternal_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min %"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="calving_ease_maternal_max"
                  value={filters.calving_ease_maternal_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max %"
                />

                <label>Milk</label>
                <input
                  class="inputBox"
                  type="number"
                  name="milk_min"
                  value={filters.milk_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min lbs"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="milk_max"
                  value={filters.milk_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max lbs"
                />

                <label>Mature Weight</label>
                <input
                  class="inputBox"
                  type="number"
                  name="mature_weight_min"
                  value={filters.mature_weight_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min lbs"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="mature_weight_max"
                  value={filters.mature_weight_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max lbs"
                />

                <label>Mature Height</label>
                <input
                  class="inputBox"
                  type="number"
                  name="mature_height_min"
                  value={filters.mature_height_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min inches"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="mature_height_max"
                  value={filters.mature_height_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max inches"
                />

                <label>Carcass Weight</label>
                <input
                  class="inputBox"
                  type="number"
                  name="carcass_weight_min"
                  value={filters.carcass_weight_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min lbs"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="carcass_weight_max"
                  value={filters.carcass_weight_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max lbs"
                />

                <label>Carcass Marbling</label>
                <input
                  class="inputBox"
                  type="number"
                  name="carcass_marbling_min"
                  value={filters.carcass_marbling_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="carcass_marbling_max"
                  value={filters.carcass_marbling_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max"
                />

                <label>Carcass Ribeye</label>
                <input
                  class="inputBox"
                  type="number"
                  name="carcass_ribeye_min"
                  value={filters.carcass_ribeye_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min square inches"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="carcass_ribeye_max"
                  value={filters.carcass_ribeye_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max square inches"
                />

                <label>Carcass Fat</label>
                <input
                  class="inputBox"
                  type="number"
                  name="carcass_fat_min"
                  value={filters.carcass_fat_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min inches"
                />
                <input
                  class="inputBox"
                  type="number"
                  name="carcass_fat_max"
                  value={filters.carcass_fat_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max inches"
                />

                <label>F11</label>
                <select
                  name="f11"
                  value={filters.f11 || ''}
                  onChange={handleFilterChange}
                >
                  <option value="">All</option>
                  <option value="Free">Free</option>
                  <option value="Not Free">Not Free</option>
                </select>

                <label>F13</label>
                <select
                  name="f13"
                  value={filters.f13 || ''}
                  onChange={handleFilterChange}
                >
                  <option value="">All</option>
                  <option value="Free">Free</option>
                  <option value="Not Free">Not Free</option>
                </select>

                <label>CHS</label>
                <select
                  name="chs"
                  value={filters.chs || ''}
                  onChange={handleFilterChange}
                >
                  <option value="">All</option>
                  <option value="Free">Free</option>
                  <option value="Not Free">Not Free</option>
                </select>

                <label>Band3</label>
                <select
                  name="band3"
                  value={filters.band3 || ''}
                  onChange={handleFilterChange}
                >
                  <option value="">All</option>
                  <option value="Free">Free</option>
                  <option value="Not Free">Not Free</option>
                </select>

                <label>Color</label>
                <select
                  name="color"
                  value={filters.color || ''}
                  onChange={handleFilterChange}
                >
                  <option value="">All Colors</option>
                  <option value="Black">Black</option>
                  <option value="White">White</option>
                  <option value="Red">Red</option>
                  <option value="Brown">Brown</option>
                  <option value="Yellow">Yellow</option>
                  <option value="Grey">Grey</option>
                  <option value="Blue Roan">Blue Roan</option>
                  <option value="Red Roan">Red Roan</option>
                  <option value="Black and White">Black and White</option>
                  <option value="Red and White">Red and White</option>
                  <option value="Dun">Dun</option>
                  <option value="Silver">Silver</option>
                  <option value="Cream">Cream</option>
                  <option value="Chocolate">Chocolate</option>
                  <option value="Dark Brown">Dark Brown</option>
                  <option value="Light Brown">Light Brown</option>
                  <option value="Brindle">Brindle</option>
                  <option value="Fawn">Fawn</option>
                  <option value="Chestnut">Chestnut</option>
                  <option value="Palomino">Palomino</option>
                </select>

                <label>Horn Status</label>
                <select
                  name="horn_status"
                  value={filters.horn_status || ''}
                  onChange={handleFilterChange}
                >
                  <option value="">All</option>
                  <option value="Polled">Polled</option>
                  <option value="Horned">Horned</option>
                </select>

                <label>Verified</label>
                <select
                  name="verified"
                  value={filters.verified || ''}
                  onChange={handleFilterChange}
                >
                  <option value="">All</option>
                  <option value="TRUE">TRUE</option>
                  <option value="FALSE">FALSE</option>
                </select>
              </>
            )}

            {selectedProductType === 'dams' && (
              <>
                <label>Milk Yield</label>
                <input
                  type="number"
                  name="milk_yield_min"
                  value={filters.milk_yield_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min liters/day"
                />
                <input
                  type="number"
                  name="milk_yield_max"
                  value={filters.milk_yield_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max liters/day"
                />

                <label>Calving Interval</label>
                <input
                  type="number"
                  name="calving_interval_min"
                  value={filters.calving_interval_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min days"
                />
                <input
                  type="number"
                  name="calving_interval_max"
                  value={filters.calving_interval_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max days"
                />

                <label>Calving Ease DIR</label>
                <input
                  type="number"
                  name="calving_ease_dir_min"
                  value={filters.calving_ease_dir_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min %"
                />
                <input
                  type="number"
                  name="calving_ease_dir_max"
                  value={filters.calving_ease_dir_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max %"
                />

                <label>Calving Ease DTRS</label>
                <input
                  type="number"
                  name="calving_ease_dtrs_min"
                  value={filters.calving_ease_dtrs_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min %"
                />
                <input
                  type="number"
                  name="calving_ease_dtrs_max"
                  value={filters.calving_ease_dtrs_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max %"
                />

                <label>Gestation Length (days)</label>
                <input
                  type="number"
                  name="gestation_length_min"
                  value={filters.gestation_length_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min days"
                />
                <input
                  type="number"
                  name="gestation_length_max"
                  value={filters.gestation_length_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max days"
                />

                <label>Birth Weight</label>
                <input
                  type="number"
                  name="birth_weight_min"
                  value={filters.birth_weight_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min lbs"
                />
                <input
                  type="number"
                  name="birth_weight_max"
                  value={filters.birth_weight_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max lbs"
                />

                <label>200 Day Weight</label>
                <input
                  type="number"
                  name="day_200_weight_min"
                  value={filters.day_200_weight_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min lbs"
                />
                <input
                  type="number"
                  name="day_200_weight_max"
                  value={filters.day_200_weight_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max lbs"
                />

                <label>400 Day Weight</label>
                <input
                  type="number"
                  name="day_400_weight_min"
                  value={filters.day_400_weight_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min lbs"
                />
                <input
                  type="number"
                  name="day_400_weight_max"
                  value={filters.day_400_weight_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max lbs"
                />

                <label>600 Day Weight</label>
                <input
                  type="number"
                  name="day_600_weight_min"
                  value={filters.day_600_weight_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min lbs"
                />
                <input
                  type="number"
                  name="day_600_weight_max"
                  value={filters.day_600_weight_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max lbs"
                />

                <label>Maternal Cow Weight</label>
                <input
                  type="number"
                  name="maternal_cow_weight_min"
                  value={filters.maternal_cow_weight_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min lbs"
                />
                <input
                  type="number"
                  name="maternal_cow_weight_max"
                  value={filters.maternal_cow_weight_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max lbs"
                />

                <label>200 Day Milk</label>
                <input
                  type="number"
                  name="day_200_milk_min"
                  value={filters.day_200_milk_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min lbs"
                />
                <input
                  type="number"
                  name="day_200_milk_max"
                  value={filters.day_200_milk_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max lbs"
                />

                <label>Scrotal Size</label>
                <input
                  type="number"
                  name="scrotal_size_min"
                  value={filters.scrotal_size_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min cm"
                />
                <input
                  type="number"
                  name="scrotal_size_max"
                  value={filters.scrotal_size_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max cm"
                />

                <label>Carcase Weight</label>
                <input
                  type="number"
                  name="carcase_weight_min"
                  value={filters.carcase_weight_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min lbs"
                />
                <input
                  type="number"
                  name="carcase_weight_max"
                  value={filters.carcase_weight_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max lbs"
                />

                <label>Eye Muscle Area</label>
                <input
                  type="number"
                  name="eye_muscle_area_min"
                  value={filters.eye_muscle_area_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min square inches"
                />
                <input
                  type="number"
                  name="eye_muscle_area_max"
                  value={filters.eye_muscle_area_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max square inches"
                />

                <label>Rib Fat</label>
                <input
                  type="number"
                  name="rib_fat_min"
                  value={filters.rib_fat_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min inches"
                />
                <input
                  type="number"
                  name="rib_fat_max"
                  value={filters.rib_fat_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max inches"
                />

                <label>Retail Beef Yield</label>
                <input
                  type="number"
                  name="retail_beef_yield_min"
                  value={filters.retail_beef_yield_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min %"
                />
                <input
                  type="number"
                  name="retail_beef_yield_max"
                  value={filters.retail_beef_yield_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max %"
                />

                <label>IMF</label>
                <input
                  type="number"
                  name="imf_min"
                  value={filters.imf_min || ''}
                  onChange={handleFilterChange}
                  placeholder="Min"
                />
                <input
                  type="number"
                  name="imf_max"
                  value={filters.imf_max || ''}
                  onChange={handleFilterChange}
                  placeholder="Max"
                />

                <label>Color</label>
                <select
                  name="color"
                  value={filters.color || ''}
                  onChange={handleFilterChange}
                >
                  <option value="">All Colors</option>
                  <option value="Black">Black</option>
                  <option value="White">White</option>
                  <option value="Red">Red</option>
                  <option value="Brown">Brown</option>
                  <option value="Yellow">Yellow</option>
                  <option value="Grey">Grey</option>
                  <option value="Blue Roan">Blue Roan</option>
                  <option value="Red Roan">Red Roan</option>
                  <option value="Black and White">Black and White</option>
                  <option value="Red and White">Red and White</option>
                  <option value="Dun">Dun</option>
                  <option value="Silver">Silver</option>
                  <option value="Cream">Cream</option>
                  <option value="Chocolate">Chocolate</option>
                  <option value="Dark Brown">Dark Brown</option>
                  <option value="Light Brown">Light Brown</option>
                  <option value="Brindle">Brindle</option>
                  <option value="Fawn">Fawn</option>
                  <option value="Chestnut">Chestnut</option>
                  <option value="Palomino">Palomino</option>
                </select>

                <label>Verified</label>
                <select
                  name="verified"
                  value={filters.verified || ''}
                  onChange={handleFilterChange}
                >
                  <option value="">All</option>
                  <option value="TRUE">TRUE</option>
                  <option value="FALSE">FALSE</option>
                </select>
              </>
            )}

          </div>
          <div className="filter-actions">
            <button class="buttonNice colorRed" onClick={applyFilters}>Apply Filters</button>
            <button class="buttonNice spaceB colorYellow" onClick={resetFilters}>Reset Filters</button>
          </div>

          <div class="fixScroll"></div>
        </aside>
      )}




      <main className="product-grid">
        {products.map((product) => {
          const productTypeKey = selectedProductType === 'sires' ? 'sire_id' : 'dam_id';
          const isSaved = savedItems.some((item) => item[productTypeKey] === product.id);

          return (
            <div
              key={product.id}
              className="product-card"
              onClick={() => handleProductClick(product.id)}
            >
              <img className='roundedIMG' src={product.picture_url || 'https://via.placeholder.com/150'} alt={product.breed} />
              <div className="product-details">
                {selectedFeatures[selectedProductType].map((feature) => (
                  <p key={feature}>
                    <strong>{feature.replace(/_/g, ' ')}:</strong> {product[feature]}
                  </p>
                ))}
              </div>
              <div
                className={`heart-icon ${isSaved ? 'saved' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleSaveItem(product.id);
                }}
              >♥</div>
            </div>
          );
        })}

      </main>


    </div>

  );

};

export default Shop;
