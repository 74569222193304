import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import './CreditCardSetup.css';

const CreditCardSetup = () => {
  const { userId } = useParams(); // Get the user ID from the URL
  const navigate = useNavigate();
  const [cardInfo, setCardInfo] = useState({
    cardNumber: '',
    cardholderName: '',
    expirationDate: '',
    cvv: '',
    billingAddress: '',
  });

  const handleInputChange = (e) => {
    setCardInfo({ ...cardInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Ensure that the expiration date is in the correct format with day set to "01"
    const formattedExpirationDate = `${cardInfo.expirationDate}-01`;
  
    // Insert credit card info into the database
    const { error } = await supabase
      .from('credit_card_info')
      .insert({
        user_id: userId,
        card_number: cardInfo.cardNumber,
        cardholder_name: cardInfo.cardholderName,
        expiration_date: formattedExpirationDate, // Use the formatted date
        cvv: cardInfo.cvv,
        billing_address: cardInfo.billingAddress,
      });
  
    if (!error) {
      navigate('/add-products'); // Redirect to the shop page after saving the card info
    } else {
      alert('Error saving credit card information');
    }
  };
  

  return (
    <div className="credit-card-setup-container">
      <h1>Enter Your Credit Card Information</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Card Number</label>
          <input
            type="text"
            name="cardNumber"
            value={cardInfo.cardNumber}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Cardholder Name</label>
          <input
            type="text"
            name="cardholderName"
            value={cardInfo.cardholderName}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Expiration Date</label>
          <input
            type="month"
            name="expirationDate"
            value={cardInfo.expirationDate}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>CVV</label>
          <input
            type="text"
            name="cvv"
            value={cardInfo.cvv}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Billing Address</label>
          <input
            type="text"
            name="billingAddress"
            value={cardInfo.billingAddress}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="submit-button">Save and Continue</button>
      </form>
    </div>
  );
};

export default CreditCardSetup;
