import React, { useState, useEffect, useRef } from 'react';
import { supabase } from './supabaseClient';
import './ChatView.css';
import AWS from 'aws-sdk';

const ChatView = ({ selectedChat, currentUserId }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);

  // AWS S3 configuration
  const s3 = new AWS.S3({
    accessKeyId: 'AKIAQWHCP2VJBRYY5YUW',
    secretAccessKey: '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',
    region: 'us-east-2',
  });

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const fetchMessages = async () => {
      const { data, error } = await supabase
        .from('messages')
        .select('*')
        .or(`sender_id.eq.${currentUserId},receiver_id.eq.${currentUserId}`)
        .order('timestamp', { ascending: true });

      if (error) {
        console.error('Error fetching messages:', error);
      } else {
        setMessages(data || []); // Ensure messages is always an array
        scrollToBottom(); // Scroll to bottom after fetching messages
      }
    };

    fetchMessages();
  }, [selectedChat, currentUserId]);

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom whenever messages are updated
  }, [messages]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    const tempMessage = {
      id: Date.now(), // Temporary ID for rendering purposes
      sender_id: currentUserId,
      receiver_id: selectedChat.id,
      message: newMessage,
      timestamp: new Date().toISOString(),
    };

    // Add the new message to the local state immediately
    setMessages(prevMessages => [...prevMessages, tempMessage]);
    setNewMessage('');

    // Now send the message to the database
    const { data, error } = await supabase
      .from('messages')
      .insert([{
        sender_id: currentUserId,
        receiver_id: selectedChat.id,
        message: newMessage,
      }]);

    if (error) {
      console.error('Error sending message:', error);
    } else if (data && data.length > 0) {
      // Replace the temporary message with the one returned from the database
      setMessages(prevMessages => prevMessages.map(msg => 
        msg.id === tempMessage.id ? data[0] : msg
      ));
    }
  };

  const handleSendImage = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const uploadParams = {
      Bucket: 'cowcum',
      Key: `${Date.now()}_${file.name}`,
      Body: file,
      ContentType: file.type,
    };

    try {
      const uploadResult = await s3.upload(uploadParams).promise();
      const imageUrl = uploadResult.Location;

      const tempMessage = {
        id: Date.now(), // Temporary ID for rendering purposes
        sender_id: currentUserId,
        receiver_id: selectedChat.id,
        message: imageUrl,
        timestamp: new Date().toISOString(),
      };

      // Add the image message to the local state immediately
      setMessages(prevMessages => [...prevMessages, tempMessage]);

      // Send image URL as a message to the database
      const { data, error } = await supabase
        .from('messages')
        .insert([{
          sender_id: currentUserId,
          receiver_id: selectedChat.id,
          message: imageUrl,
        }]);

      if (error) {
        console.error('Error sending image:', error);
      } else if (data && data.length > 0) {
        setMessages(prevMessages => prevMessages.map(msg => 
          msg.id === tempMessage.id ? data[0] : msg
        ));
      }
    } catch (err) {
      console.error('Error uploading image to S3:', err);
    }
  };

  const handleSendMoneyRequest = () => {
    const moneyMessage = "💵 Request for Payment";

    const tempMessage = {
      id: Date.now(),
      sender_id: currentUserId,
      receiver_id: selectedChat.id,
      message: moneyMessage,
      timestamp: new Date().toISOString(),
    };

    setMessages(prevMessages => [...prevMessages, tempMessage]);

    supabase
      .from('messages')
      .insert([{
        sender_id: currentUserId,
        receiver_id: selectedChat.id,
        message: moneyMessage,
      }])
      .then(({ data, error }) => {
        if (error) {
          console.error('Error sending payment request:', error);
        } else if (data && data.length > 0) {
          setMessages(prevMessages => prevMessages.map(msg => 
            msg.id === tempMessage.id ? data[0] : msg
          ));
        }
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage(e);
    }
  };

  return (
    <div className="chat-view-container">
      <div className="chat-header">
        <img src={selectedChat.imgSrc} alt={selectedChat.name} className="chat-header-img" />
        <div className="chat-header-info">
          <h2 onClick={() => window.location.href = `/seller-reviews/${selectedChat.id}`}>{selectedChat.name}</h2>
        </div>
      </div>
      <div className="chat-messages">
        {messages.map((msg) => (
          <div key={msg.id} className={`chat-message ${msg.sender_id === currentUserId ? 'sent' : 'received'}`}>
            {msg.message.startsWith('http') ? (
              <img src={msg.message} alt="" className="chat-image" />
            ) : (
              <p>{msg.message}</p>
            )}
            <span className="chat-message-time">{new Date(msg.timestamp).toLocaleString()}</span>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input-container">
        <input
          type="text"
          placeholder="Aa"
          className="chat-input"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          onKeyDown={handleKeyPress}
        />
        <div className="chat-input-actions">
          <label className="custom-file-upload">
            <input type="file" onChange={handleSendImage} className="chat-input-file" /> 📷
          </label>
          <button onClick={handleSendMoneyRequest} className="chat-input-button">💵</button>
          <button onClick={handleSendMessage} className="chat-input-button">Send</button>
        </div>
      </div>
    </div>
  );
};

export default ChatView;
