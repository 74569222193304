import React, { useState } from 'react';
import AWS from 'aws-sdk';
import { supabase } from './supabaseClient';
import { v4 as uuidv4 } from 'uuid';
import './ProductForm.css';

// Configure AWS S3
AWS.config.update({
  accessKeyId: 'AKIAQWHCP2VJBRYY5YUW',
  secretAccessKey: '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',
  region: 'us-east-2',
});

const s3 = new AWS.S3();

const ProductForm = ({ product, onSubmit, onClose }) => {
  const [formData, setFormData] = useState(product);
  const [imageFile, setImageFile] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, imgSrc: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImageToS3 = async () => {
    if (!imageFile) {
        console.log('No image file selected');
        return '';
    }

    try {
        const params = {
            Bucket: 'cowcum',
            Key: `images/${Date.now()}_${imageFile.name}`,
            Body: imageFile,
            ContentType: imageFile.type,
        };

        console.log('Uploading image to S3 with params:', params);

        const upload = await s3.upload(params).promise();

        console.log('Image uploaded successfully:', upload.Location);

        return upload.Location;
    } catch (error) {
        console.error('Error uploading image to S3:', error);
        return '';
    }
};

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('Form submitted');

    const pictureUrl = await uploadImageToS3();

    console.log('Image uploaded to S3:', pictureUrl);

    if (!formData.productType) {
        alert('Product type must be selected');
        return;
    }

    const newId = uuidv4(); // Generate a new UUID
    const userId = localStorage.getItem('userId'); // Retrieve user ID from localStorage

    // const userId = 'b8bc084a-4ebe-4621-9502-6f646e537863'; // Dummy user ID

    // Prepare the data to insert (only include the fields that exist in your table schema)
    const dataToInsert = {
        id: newId, // UUID for the ID
        user_id: userId, // Dummy user ID
        bull_name: formData.bull_name,
        breed: formData.breed,
        price: formData.price,
        registration_no: formData.registration_no,
        born: formData.born,
        breed_association: formData.breed_association,
        breed_association_website: formData.breed_association_website,
        picture_url: pictureUrl, 
        verified: "FALSE",
        ...(formData.productType === 'dams' ? {
            grade: formData.grade,
            stage_of_development: formData.stage_of_development,
            milk_yield: formData.milk_yield,
            calving_interval: formData.calving_interval,
            calving_ease_dir: formData.calving_ease_dir,
            calving_ease_dtrs: formData.calving_ease_dtrs,
            gestation_length: formData.gestation_length,
            birth_weight: formData.birth_weight,
            day_200_weight: formData.day_200_weight,
            day_400_weight: formData.day_400_weight,
            day_600_weight: formData.day_600_weight,
            maternal_cow_weight: formData.maternal_cow_weight,
            day_200_milk: formData.day_200_milk,
            scrotal_size: formData.scrotal_size,
            carcase_weight: formData.carcase_weight,
            eye_muscle_area: formData.eye_muscle_area,
            rib_fat: formData.rib_fat,
            retail_beef_yield: formData.retail_beef_yield,
            imf: formData.imf,
            awards: formData.awards,
        } : {
            // Additional fields for sires
            f11: formData.f11,
            f13: formData.f13,
            chs: formData.chs,
            band3: formData.band3,
            makeup: formData.makeup,
            color: formData.color,
            awards: formData.awards,
            horn_status: formData.horn_status,
            description: formData.description,
            percent: formData.percent,
            calving_ease_direct: formData.calving_ease_direct,
            birth_weight: formData.birth_weight,
            weaning_weight: formData.weaning_weight,
            yearling_weight: formData.yearling_weight,
            dry_matter_intake: formData.dry_matter_intake,
            yearling_height: formData.yearling_height,
            scrotal_circumference: formData.scrotal_circumference,
            docility: formData.docility,
            claw_angle: formData.claw_angle,
            foot_angle: formData.foot_angle,
            heifer_pregnancy: formData.heifer_pregnancy,
            calving_ease_maternal: formData.calving_ease_maternal,
            milk: formData.milk,
            mature_weight: formData.mature_weight,
            mature_height: formData.mature_height,
            carcass_weight: formData.carcass_weight,
            carcass_marbling: formData.carcass_marbling,
            carcass_ribeye: formData.carcass_ribeye,
            carcass_fat: formData.carcass_fat,
        }),
    };

    const { error } = await supabase
      .from(formData.productType) // This will be either 'sires' or 'dams'
      .insert([dataToInsert]);

    if (error) {
        alert('Error adding product:', error.message);
    } else {
        console.log('Product added to Supabase with ID:', newId);
        onSubmit({ ...formData, id: newId, user_id: userId, picture_url: pictureUrl });
    }
  };

  return (
    <div className="product-form-container">
      <div className="form-header">
        <h2>Add {formData.productType === 'sires' ? 'Sire' : 'Dam'}</h2>
        <button className="close-button" onClick={onClose}>✖</button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="productType">Product Type</label>
          <select name="productType" value={formData.productType} onChange={handleChange} required>
            <option value="">Select Type</option>
            <option value="sires">Sire</option>
            <option value="dams">Dam</option>
          </select>
        </div>
        <div className="form-group">
          <label>Animal Name</label>
          <input type="text" name="bull_name" value={formData.bull_name} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Breed</label>
          <input type="text" name="breed" value={formData.breed} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Price</label>
          <input type="number" name="price" value={formData.price} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Registration No</label>
          <input type="text" name="registration_no" value={formData.registration_no} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Breed Association</label>
          <input type="text" name="breed_association" value={formData.breed_association} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Breed Association Website</label>
          <input type="text" name="breed_association_website" value={formData.breed_association_website} onChange={handleChange} />
        </div>
        
        {formData.productType === 'dams' ? (
          <>
            <div className="form-group">
              <label>Grade</label>
              <input type="text" name="grade" value={formData.grade} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Stage of Development</label>
              <input type="text" name="stage_of_development" value={formData.stage_of_development} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Milk Yield</label>
              <input type="text" name="milk_yield" value={formData.milk_yield} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Calving Interval</label>
              <input type="text" name="calving_interval" value={formData.calving_interval} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Calving Ease DIR</label>
              <input type="text" name="calving_ease_dir" value={formData.calving_ease_dir} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Calving Ease DTRS</label>
              <input type="text" name="calving_ease_dtrs" value={formData.calving_ease_dtrs} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Gestation Length</label>
              <input type="text" name="gestation_length" value={formData.gestation_length} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Birth Weight</label>
              <input type="text" name="birth_weight" value={formData.birth_weight} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Day 200 Weight</label>
              <input type="text" name="day_200_weight" value={formData.day_200_weight} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Day 400 Weight</label>
              <input type="text" name="day_400_weight" value={formData.day_400_weight} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Day 600 Weight</label>
              <input type="text" name="day_600_weight" value={formData.day_600_weight} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Maternal Cow Weight</label>
              <input type="text" name="maternal_cow_weight" value={formData.maternal_cow_weight} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Day 200 Milk</label>
              <input type="text" name="day_200_milk" value={formData.day_200_milk} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Scrotal Size</label>
              <input type="text" name="scrotal_size" value={formData.scrotal_size} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Carcase Weight</label>
              <input type="text" name="carcase_weight" value={formData.carcase_weight} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Eye Muscle Area</label>
              <input type="text" name="eye_muscle_area" value={formData.eye_muscle_area} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Rib Fat</label>
              <input type="text" name="rib_fat" value={formData.rib_fat} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Retail Beef Yield</label>
              <input type="text" name="retail_beef_yield" value={formData.retail_beef_yield} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>IMF</label>
              <input type="text" name="imf" value={formData.imf} onChange={handleChange} />
            </div>
          </>
        ) : (
          <>
            <div className="form-group">
              <label>F11</label>
              <input type="text" name="f11" value={formData.f11} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>F13</label>
              <input type="text" name="f13" value={formData.f13} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>CHS</label>
              <input type="text" name="chs" value={formData.chs} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Band3</label>
              <input type="text" name="band3" value={formData.band3} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Makeup</label>
              <input type="text" name="makeup" value={formData.makeup} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Color</label>
              <input type="text" name="color" value={formData.color} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Awards</label>
              <input type="text" name="awards" value={formData.awards} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Horn Status</label>
              <input type="text" name="horn_status" value={formData.horn_status} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input type="text" name="description" value={formData.description} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Percent</label>
              <input type="text" name="percent" value={formData.percent} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Calving Ease Direct</label>
              <input type="text" name="calving_ease_direct" value={formData.calving_ease_direct} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Birth Weight</label>
              <input type="text" name="birth_weight" value={formData.birth_weight} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Weaning Weight</label>
              <input type="text" name="weaning_weight" value={formData.weaning_weight} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Yearling Weight</label>
              <input type="text" name="yearling_weight" value={formData.yearling_weight} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Dry Matter Intake</label>
              <input type="text" name="dry_matter_intake" value={formData.dry_matter_intake} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Yearling Height</label>
              <input type="text" name="yearling_height" value={formData.yearling_height} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Scrotal Circumference</label>
              <input type="text" name="scrotal_circumference" value={formData.scrotal_circumference} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Docility</label>
              <input type="text" name="docility" value={formData.docility} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Claw Angle</label>
              <input type="text" name="claw_angle" value={formData.claw_angle} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Foot Angle</label>
              <input type="text" name="foot_angle" value={formData.foot_angle} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Heifer Pregnancy</label>
              <input type="text" name="heifer_pregnancy" value={formData.heifer_pregnancy} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Calving Ease Maternal</label>
              <input type="text" name="calving_ease_maternal" value={formData.calving_ease_maternal} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Milk</label>
              <input type="text" name="milk" value={formData.milk} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Mature Weight</label>
              <input type="text" name="mature_weight" value={formData.mature_weight} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Mature Height</label>
              <input type="text" name="mature_height" value={formData.mature_height} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Carcass Weight</label>
              <input type="text" name="carcass_weight" value={formData.carcass_weight} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Carcass Marbling</label>
              <input type="text" name="carcass_marbling" value={formData.carcass_marbling} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Carcass Ribeye</label>
              <input type="text" name="carcass_ribeye" value={formData.carcass_ribeye} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Carcass Fat</label>
              <input type="text" name="carcass_fat" value={formData.carcass_fat} onChange={handleChange} />
            </div>
          </>
        )}

        <div className="form-group">
          <label>Born</label>
          <input type="date" name="born" value={formData.born} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Image</label>
          <input type="file" name="image" accept="image/*" onChange={handleImageChange} />
        </div>
        {formData.imgSrc && <img src={formData.imgSrc} alt="Preview" className="image-preview" />}
        <button type="submit" className="submit-button">Save</button>
      </form>
    </div>
  );
};

export default ProductForm;
