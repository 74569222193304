import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';

const Header = ({ isSignedIn, setIsSignedIn }) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('isSignedIn');
    setIsSignedIn(false);
    navigate('/');
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleNavigation = (path) => {
    if (isSignedIn) {
      navigate(path);
    } else {
      navigate('/login');
    }
    setIsExpanded(false);
  };

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <header>
      <nav className={`navBarLander navbar navbar-expand-lg navbar-light newZindexLander ${isExpanded ? 'expanded' : ''}`}>
        <div className="container-fluid">
          <div className="herdMateFont navBarLogo navbar-brand" onClick={() => navigate('/')}>
            HerdMate
          </div>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleExpansion}
            aria-controls="navbarContent"
            aria-expanded={isExpanded}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`navbar-collapse ${isExpanded ? 'show' : ''}`} id="navbarContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <div className="nav-link navBody" onClick={() => handleNavigation('/shop')}>
                  Marketplace
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link navBody" onClick={() => handleNavigation('/saved')}>
                  Saved
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link navBody" onClick={() => handleNavigation('/messaging/1')}>
                  Messages
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link navBody" onClick={() => handleNavigation('/profile')}>
                  My Profile
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link navBody" onClick={() => handleNavigation('/add')}>
                  Sell Products
                </div>
              </li>
              <li className="nav-item">
                {isSignedIn ? (
                  <div className="loginButtonRED herdMateFont" onClick={handleLogout}>
                    Logout
                  </div>
                ) : (
                  <div className="loginButton herdMateFont" onClick={handleLogin}>
                    Login
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;