import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import './SellerReviews.css';

const SellerReviews = () => {
  const { sellerId } = useParams();
  const navigate = useNavigate();
  const [sellerInfo, setSellerInfo] = useState({});
  const [reviews, setReviews] = useState([]);
  const [products, setProducts] = useState([]);
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    const fetchSellerInfo = async () => {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', sellerId)
        .single();

      if (error) {
        console.error('Error fetching seller info:', error);
      } else {
        setSellerInfo(data);
      }
    };

    const fetchReviews = async () => {
      const { data, error } = await supabase
        .from('seller_reviews')
        .select('*')
        .eq('seller_id', sellerId)

      if (error) {
        console.error('Error fetching reviews:', error);
      } else {
        setReviews(data);
        calculateAverageRating(data);
      }
    };

    const fetchSellerProducts = async () => {
      const { data: siresData, error: siresError } = await supabase
        .from('sires')
        .select('*')
        .eq('user_id', sellerId);

      const { data: damsData, error: damsError } = await supabase
        .from('dams')
        .select('*')
        .eq('user_id', sellerId);

      if (siresError || damsError) {
        console.error('Error fetching seller products:', siresError || damsError);
      } else {
        setProducts([...(siresData || []), ...(damsData || [])]);
      }
    };

    const calculateAverageRating = (reviews) => {
      if (reviews.length === 0) {
        setAverageRating(0);
        return;
      }
      const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
      const avgRating = totalRating / reviews.length;
      setAverageRating(avgRating);
    };

    fetchSellerInfo();
    fetchReviews();
    fetchSellerProducts();
  }, [sellerId]);

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < Math.floor(rating)) {
        stars.push(<span key={i}>&#9733;</span>); // Filled star
      } else if (i < rating) {
        stars.push(<span key={i}>&#9733;</span>); // Half star, or a filled star if you prefer
      } else {
        stars.push(<span key={i}>&#9734;</span>); // Empty star
      }
    }
    return stars;
  };

  return (
    <div className="seller-reviews-container">
      <div className="seller-info">
        <img src={sellerInfo.profile_picture || 'https://via.placeholder.com/150'} alt={sellerInfo.full_name} className="profile-picture" />
        <div className="seller-details">
          <h1>{sellerInfo.full_name}</h1>
          <p><strong>Farm Name:</strong> {sellerInfo.farm_name}</p>
          <p><strong>Location:</strong> {sellerInfo.City}, {sellerInfo.State}</p>
          <p><strong>Website:</strong> <a href={sellerInfo.farm_website} target="_blank" rel="noopener noreferrer">{sellerInfo.farm_website}</a></p>
        </div>
      </div>

      <div className="products-section">
        <h2>Products by {sellerInfo.full_name}</h2>
        <div className="product-grid">
          {products.map((product) => (
            <div key={product.id} className="product-card" onClick={() => navigate(`/product/${product.id}`)}>
              <img src={product.picture_url || 'https://via.placeholder.com/150'} alt={product.breed} />
              <div className="product-details">
                <h3>{product.bull_name}</h3>
                <p><strong>Breed:</strong> {product.breed}</p>
                <p><strong>Price:</strong> ${product.price}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="reviews-section">
        <h2>Reviews</h2>
        <div className="average-rating">
          <h3>Overall Rating: {renderStars(averageRating)}</h3>
          <p>({averageRating.toFixed(1)} out of 5)</p>
        </div>
        <div className="reviews-list">
          {reviews.map((review) => (
            <div key={review.id} className="review-item">
              <p><strong>Rating:</strong> {review.rating} / 5</p>
              <p>{review.review}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SellerReviews;
