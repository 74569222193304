import React, { useState } from 'react';
import { supabase } from './supabaseClient';
import { useNavigate } from 'react-router-dom';
import './ResetPassword.css';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  

  const handleGoBack = () => {
    navigate('/'); // Navigate back to the home page
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError(null);

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const { error } = await supabase.auth.updateUser({
        password
      });

      if (error) {
        setError(error.message);
      } else {
        setMessage('Your password has been reset. You can now log in with your new password.');
        setTimeout(() => {
          navigate('/login');
        }, 3000); // Redirect to login after 3 seconds
      }
    } catch (err) {
      console.error("Error resetting password:", err);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="reset-password-container">
      <h1>Reset Password</h1>
      <form onSubmit={handlePasswordReset}>
        <div className="form-group">
          <label>New Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Confirm New Password</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        {error && <p className="error">{error}</p>}
        {message && <p className="message">{message}</p>}
        <button type="submit" className="submit-button">Reset Password</button>
      </form>
      <button onClick={handleGoBack} className="back-button">Back to Home</button> {/* Back button */}
    </div>
  );
};

export default ResetPassword;
