import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import AWS from 'aws-sdk';
import './ProfilePage.css';

const ProfilePage = () => {
  const userId = localStorage.getItem('userId'); // Retrieve user ID from localStorage

  // const userId = 'b8bc084a-4ebe-4621-9502-6f646e537863'; // Replace with your actual user ID or fetch it from context/auth state
  const [profileData, setProfileData] = useState({
    full_name: '',
    email: '',
    phone_number: '',
    address: '',
    farm_name: '',
    farm_website: '',
    State: '',
    City: '',
    zipcode: '',
    legalAgreement: false,
    oldPassword: '',
    password: '',
    confirmPassword: '',
    profile_picture: '',
  });
  const [imageFile, setImageFile] = useState(null);

  // Configure AWS S3
  AWS.config.update({
    accessKeyId: 'AKIAQWHCP2VJBRYY5YUW',
    secretAccessKey: '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',
    region: 'us-east-2',
  });

  const s3 = new AWS.S3();

  useEffect(() => {
    const fetchProfile = async () => {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', userId)
        .single();

      if (error) {
        console.error('Error fetching profile:', error);
      } else {
        setProfileData((prev) => ({
          ...prev,
          ...data,
        }));
      }
    };

    fetchProfile();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProfileData({
      ...profileData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileData({ ...profileData, profile_picture: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImageToS3 = async () => {
    if (!imageFile) return profileData.profile_picture; // Return the existing profile picture if no new image is selected

    try {
      const params = {
        Bucket: 'cowcum',
        Key: `profile_pictures/${userId}_${imageFile.name}`,
        Body: imageFile,
        ContentType: imageFile.type,
      };

      const upload = await s3.upload(params).promise();
      return upload.Location; // Return the URL of the uploaded image
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      return profileData.profile_picture; // Fallback to the existing profile picture if upload fails
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const profilePictureUrl = await uploadImageToS3();

    
    const { error } = await supabase
      .from('users')
      .update({
        full_name: profileData.full_name,
        email: profileData.email,
        phone_number: profileData.phone_number,
        address: profileData.address,
        farm_name: profileData.farm_name,
        farm_website: profileData.farm_website,
        State: profileData.State,
        City: profileData.City,
        zipcode: profileData.zipcode,
        profile_picture: profilePictureUrl,
      })
      .eq('id', userId);

    if (error) {
      alert('Error updating profile:', error.message);
    } else {
      alert('Profile updated successfully!');
    }
  };

  return (
    <div className="profile-page-container">
      <h1>Farm Owner Profile</h1>
      <form onSubmit={handleSubmit} className="profile-form">
        <div className="form-section">
          <h2>Profile Picture</h2>
          <div className="form-group">
          {profileData.profile_picture && <img src={profileData.profile_picture} alt="Profile" className="profile-picture-preview" />}

            <label htmlFor="profile_picture">Change Profile Picture</label>
            <input type="file" id="profile_picture" accept="image/*" onChange={handleImageChange} />
          </div>
        </div>

        <div className="form-section">
          <h2>Personal Details</h2>
          <div className="form-group">
            <label htmlFor="full_name">Name</label>
            <input type="text" id="full_name" name="full_name" value={profileData.full_name} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" value={profileData.email} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="phone_number">Phone</label>
            <input type="tel" id="phone_number" name="phone_number" value={profileData.phone_number} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="address">Address</label>
            <input type="text" id="address" name="address" value={profileData.address} onChange={handleChange} />
          </div>
        </div>

        <div className="form-section">
          <h2>Farm Details</h2>
          <div className="form-group">
            <label htmlFor="farm_name">Farm Name</label>
            <input type="text" id="farm_name" name="farm_name" value={profileData.farm_name} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="farm_website">Farm Website</label>
            <input type="text" id="farm_website" name="farm_website" value={profileData.farm_website} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="State">State</label>
            <input type="text" id="State" name="State" value={profileData.State} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="City">City</label>
            <input type="text" id="City" name="City" value={profileData.City} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="zipcode">Zipcode</label>
            <input type="text" id="zipcode" name="zipcode" value={profileData.zipcode} onChange={handleChange} />
          </div>
        </div>

        <div className="form-section">
          <h2>Legal Information</h2>
          <div className="form-group">
            <label htmlFor="legalInfo">Terms and Conditions</label>
            <textarea id="legalInfo" name="legalInfo" value={profileData.legalInfo} onChange={handleChange} readOnly />
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="legalAgreement"
                checked={profileData.legalAgreement}
                onChange={handleChange}
              />
              I agree to the Terms and Conditions
            </label>
          </div>
        </div>



        <button type="submit" className="save-button">Save Changes</button>
      </form>
    </div>
  );
};

export default ProfilePage;
