import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import LoginSignup from './components/LoginSignup';
import AccountSetup from './components/AccountSetup';
import Shop from './components/Shop';
import ProductDetail from './components/ProductDetail';
import Messaging from './components/Messaging';
import Saved from './components/Saved';
import AddProducts from './components/AddProducts';
import Profile from './components/ProfilePage';
import SellerReviews from './components/SellerReviews';
import Header from './components/Header'; 
import AddProductsSetup from './components/AddProductsSetup';
import CreditCardSetup from './components/CreditCardSetup'; 
import Signup from './components/Signup';
import ForgotPassword from './components/ForgotPassword'; 
import ResetPassword from './components/ResetPassword'; 

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const signedInStatus = localStorage.getItem('isSignedIn') === 'true';
    setIsSignedIn(signedInStatus);
  }, []);

  const handleSignIn = (status) => {
    setIsSignedIn(status);
    localStorage.setItem('isSignedIn', status.toString());
  };

  return (
    <Router>
      <Header isSignedIn={isSignedIn} setIsSignedIn={handleSignIn} />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<LoginSignup setIsSignedIn={handleSignIn} />} />
        <Route path="/account-setup" element={<AccountSetup setIsSignedIn={handleSignIn} />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/product/:productId" element={<ProductDetail />} />
        <Route path="/messaging/:productId" element={<Messaging />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/saved" element={<Saved />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/add" element={<AddProducts />} />
        <Route path="/add-products" element={<AddProductsSetup />} />
        <Route path="/credit-card-setup/:userId" element={<CreditCardSetup />} />
        <Route path="/seller-reviews/:sellerId" element={<SellerReviews />} />
      </Routes>
    </Router>
  );
}

export default App;