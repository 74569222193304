import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import './LoginSignup.css';

const LoginSignup = ({ setIsSignedIn }) => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    
    console.log("Form Data:", formData);

    try {
      console.log("Attempting login with Supabase...");
      const { data, error } = await supabase.auth.signInWithPassword({
        email: formData.email,
        password: formData.password
      });

      console.log("Supabase response data:", data);
      console.log("Supabase response error:", error);

      if (error) {
        console.error("Login Error:", error);
        setError(error.message);
        return;
      }

      if (data?.user) {
        console.log("Login Successful, User ID:", data.user.id);
        localStorage.setItem('userId', data.user.id);
        localStorage.setItem('isSignedIn', 'true');
        setIsSignedIn(true);
        navigate('/shop');
      } else {
        setError('Invalid credentials or user does not exist');
        console.log("User not found or invalid credentials.");
      }
    } catch (err) {
      console.error("Unexpected error during login:", err);
      setError('Something went wrong. Please try again.');
    }
  };

  return (<section className="vh-100 loginBackground">
    <div className="container py-5 h-100">
      <div className="moveCard row d-flex justify-content-center align-items-center ">
        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
          <div className="card shadow-2-strong cardBorder">
            <div className="card-body p-5 text-center">
              <h3 className="mb-5">Sign in</h3>
              <form onSubmit={handleLogin}>
                <div className="form-outline mb-4">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control form-control-lg"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                  <label className="form-label" htmlFor="email">Email</label>
                </div>
                <div className="form-outline mb-4">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="form-control form-control-lg"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                  />
                  <label className="form-label" htmlFor="password">Password</label>
                </div>
                {error && <p className="text-danger">{error}</p>}
                <button type="submit" className="btn btn-primary btn-lg btn-block">Login</button>
              </form>
              <p className="mt-3">
                <span className="toggle-form" onClick={() => navigate('/signup')}>Don't have an account? Sign up</span>
              </p>
              <p>
                <span className="toggle-form" onClick={() => navigate('/forgot-password')}>Forgot Password?</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  );
};

export default LoginSignup;