import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import './Saved.css';

const Saved = () => {
  const navigate = useNavigate();

  const userId = localStorage.getItem('userId'); // Retrieve user ID from localStorage

  // const userId = 'b8bc084a-4ebe-4621-9502-6f646e537863'; // Hardcoded user ID for demonstration

  const [savedItems, setSavedItems] = useState([]);

  useEffect(() => {
    const fetchSavedItems = async () => {
      const { data: savedData, error } = await supabase
        .from('saved_listings')
        .select('sire_id, dam_id, timestamp')
        .eq('user_id', userId);
  
      if (error) {
        console.error('Error fetching saved items:', error);
        return;
      }

      const productDetails = await Promise.all(savedData.map(async item => {
        if (item.sire_id) {
          const { data: sireData, error: sireError } = await supabase
            .from('sires')
            .select('*')
            .eq('id', item.sire_id)
            .single();

          if (sireError) {
            console.error('Error fetching sire data:', sireError);
            return null;
          }

          return { ...sireData, productType: 'sire' };
        } else if (item.dam_id) {
          const { data: damData, error: damError } = await supabase
            .from('dams')
            .select('*')
            .eq('id', item.dam_id)
            .single();

          if (damError) {
            console.error('Error fetching dam data:', damError);
            return null;
          }

          return { ...damData, productType: 'dam' };
        }

        return null;
      }));

      setSavedItems(productDetails.filter(item => item !== null));
    };
  
    fetchSavedItems();
  }, [userId]);
  
  const removeItem = async (id, productType) => {
    const confirmRemoval = window.confirm("Are you sure you want to remove this item from your saved listings?");
    if (!confirmRemoval) return;
  
    try {
      const { error } = await supabase
        .from('saved_listings')
        .delete()
        .eq('user_id', userId)
        .eq(productType === 'sire' ? 'sire_id' : 'dam_id', id);
  
      if (error) {
        console.error('Error removing saved item:', error);
      } else {
        setSavedItems(savedItems.filter(item => item.id !== id));
        console.log('Item removed successfully from the database');
      }
    } catch (error) {
      console.error('Error during deletion:', error);
    }
  };

  const handleItemClick = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <div className="saved-container">
      <h1>Saved Items</h1>
      <div className="saved-grid">
        {savedItems.map(item => (
          <div key={item.id} className="saved-item" onClick={() => handleItemClick(item.id)}>
            <img src={item.picture_url || 'https://via.placeholder.com/150'} alt={item.bull_name} className="saved-item-img" />
            <div className="saved-item-details">
              <h3>{item.bull_name}</h3>
              <p><strong>Breed:</strong> {item.breed}</p>
              <p><strong>Age:</strong> {new Date().getFullYear() - new Date(item.born).getFullYear()} years</p>
              <p><strong>Price:</strong> ${item.price}</p>
              <p><strong>Location:</strong> {item.state}</p>
            </div>
            <button className="remove-item-button" onClick={(e) => {
              e.stopPropagation(); // Prevent triggering handleItemClick
              removeItem(item.id, item.productType);
            }}>✖</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Saved;
