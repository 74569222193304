import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';
import asray from './images/asray.jpeg';
import justin from './images/justin.jpeg';
import arjun from './images/arjun.jpeg';
import hannah from './images/hannah.jpeg';

const LandingPage = () => {
  const navigate = useNavigate();

  const handleLoginSignup = () => {
    localStorage.setItem('userId', null);
    navigate('/login');
  };

  return (
    <div className="landing-page-container">
      <div className="cowBG">
     
        {/* <nav className="navBarLander navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <a className="herdMateFont navBarLogo navbar-brand" href="#">HerdMate</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link navBody" href="#">Marketplace</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link navBody" href="#">Saved</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link navBody" href="#">Message</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link navBody" href="#">My Profile</a>
                </li>
              </ul>
              <a className="loginButton herdMateFont navWholeBody">Login</a>
            </div>
          </div>
        </nav> */}

        <h1 className="title center-title herdMateFont">Welcome To HerdMate</h1>
        <h2 className="center-header herdMateDesc">Set your herd apart with high-quality bull semen and cow embryos produced by trusted farmers located countrywide.</h2>
        <button className="joinButton" onClick={handleLoginSignup}>
          JOIN US TODAY
        </button>
      </div>

      <div className="what-we-do-section">
        <h2 className="HerdMateh2">What We Do</h2>
        <p className="HerdMateh5">HerdMate is your trusted online marketplace for sourcing and listing premium bull semen and cow embryos.</p>
        <div className="features">
          <div className="feature-item">
            <h3 className="HerdMateh3">Quality Genetics</h3>
            <p className="HerdMateh5">We provide access to the best genetics in the industry, ensuring healthier and more productive herds.</p>
          </div>
          <div className="feature-item">
            <h3 className="HerdMateh3">Verified Sellers</h3>
            <p className="HerdMateh5">Our platform ensures that all sellers are verified, so you can trust the quality of the products you purchase.</p>
          </div>
          <div className="feature-item">
            <h3 className="HerdMateh3">Easy Transactions</h3>
            <p className="HerdMateh5">HerdMate simplifies the buying and selling process with secure payment options and seamless communication.</p>
          </div>
        </div>
      </div>

      <div className="additional-section">
        <h2 className="HerdMateh2">Why Choose Us?</h2>
        <p className="HerdMateh5">At HerdMate, we are committed to providing farmers with the resources they need to improve their herds.</p>
      </div>

      <div className="team-section">
        <h2>Meet Our Team</h2>
        <div className="team-members">
          <div className="team-member">
            <img src={asray} alt="Asray Gopa" />
            <h3>Asray Gopa</h3>
            <p>Founding Partner</p>
          </div>
          <div className="team-member">
            <img src={arjun} alt="Arjun Patel" />
            <h3>Arjun Patel</h3>
            <p>Founding Partner</p>
          </div>
          <div className="team-member">
            <img src={hannah} alt="Hannah Everhart" />
            <h3>Hannah Everhart</h3>
            <p>Founding Partner</p>
          </div>
          <div className="team-member">
            <img src={justin} alt="Justin Moran" />
            <h3>Justin Moran</h3>
            <p>Founding Partner</p>
          </div>
        </div>
      </div>

      <div className="contact-section">
        <h2>Contact Us</h2>
        <p>If you have any questions or need assistance, feel free to reach out to us.</p>
        <form className="contact-form">
          <input type="text" placeholder="Your Name" required />
          <input type="email" placeholder="Your Email" required />
          <textarea placeholder="Your Message" required></textarea>
          <button type="submit" className="contact-button">Send Message</button>
        </form>
      </div>

      <div className="footer-section">
        <p>&copy; 2024 HerdMate. All rights reserved.</p>
      </div>
    </div>
  );
};

export default LandingPage;