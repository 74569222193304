// ProductDetail.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import './ProductDetail.css';

const supabaseUrl = 'https://nfzmaxhwvkatvzwftqvk.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5mem1heGh3dmthdHZ6d2Z0cXZrIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTM3MzQ5ODMsImV4cCI6MjAyOTMxMDk4M30.1ZJ3vfmYswkrIeIuDZSvgg3LaA7TpXEhNUoDHrMEoRg';
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const ProductDetail = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const currentUserId = localStorage.getItem('userId');

  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const { data: siresData, error: siresError } = await supabase
          .from('sires')
          .select('*')
          .eq('id', productId)
          .single();

        if (siresError && !siresData) {
          const { data: damsData, error: damsError } = await supabase
            .from('dams')
            .select('*')
            .eq('id', productId)
            .single();

          if (damsError) {
            setError(damsError.message);
          } else {
            setProduct(damsData);
            setBackgroundImage(damsData.picture_url || 'https://via.placeholder.com/1200');
          }
        } else {
          setProduct(siresData);
          setBackgroundImage(siresData.picture_url || 'https://via.placeholder.com/1200');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  const handleMessageSeller = async () => {
    if (!product) return;

    const { data, error } = await supabase
      .from('messages')
      .select('*')
      .eq('sender_id', currentUserId)
      .eq('receiver_id', product.user_id)
      .single();

    if (error && error.code !== 'PGRST116') {
      console.error('Error checking existing chat:', error);
      return;
    }

    if (!data) {
      const initialMessage = `Hi, I am interested in "${product.bull_name || product.dam_name}" and would like to know more about it.`;

      const { error: insertError } = await supabase
        .from('messages')
        .insert([
          {
            sender_id: currentUserId,
            receiver_id: product.user_id,
            message: initialMessage,
          },
        ]);

      if (insertError) {
        console.error('Error creating initial message:', insertError);
        return;
      }
    }

    navigate(`/messaging/${product.user_id}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!product) return <div>Product not found</div>;

  return (
    <div className="product-detail-container">
      <Link to="/shop" className="close-button">X</Link>
      <div 
        className="blurred-background" 
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <div className="product-content">
        <div className="product-header">
          <img
            src={product.picture_url || 'https://via.placeholder.com/1200'}
            alt={product.bull_name || product.dam_name || 'Product Image'}
            className="MOOOOOOO000000000oooooo_Hi_Asray_Gooper"
          />
          <div className="product-header-details">
            <h1 className="PDTitle">Hello, My Name Is <strong>{product.bull_name || product.dam_name || 'Product Name'}</strong>!</h1>
            <div className="middleThing">
              <p><strong>Breed:</strong> {product.breed}</p>
              <p className="spaciungM"><strong>Price:</strong> ${product.price}</p>
            </div>
          </div>
        </div>

        <div className="product-grid">
          <div className="grid-item bShadow">
            <h2>Main Details</h2>
            <p><strong>Registration Number:</strong> {product.registration_no}</p>
            <p><strong>Born:</strong> {new Date(product.born).toLocaleDateString()}</p>
            <p><strong>Breed Association:</strong> {product.breed_association}</p>
            <p>
              <strong>Breed Association Website:</strong>{' '}
              <a href={product.breed_association_website} target="_blank" rel="noopener noreferrer">
                {product.breed_association_website}
              </a>
            </p>
            <p><strong>Description:</strong> {product.description}</p>
          </div>

          <div className="grid-item bShadow">
            <h2>Characteristics & Traits</h2>
            <div className="characteristics-grid">
              {product.birth_weight && <p><strong>Birth Weight:</strong> {product.birth_weight} lbs</p>}
              {product.weaning_weight && <p><strong>Weaning Weight:</strong> {product.weaning_weight} lbs</p>}
              {product.yearling_weight && <p><strong>Yearling Weight:</strong> {product.yearling_weight} lbs</p>}
              {product.scrotal_circumference && <p><strong>Scrotal Circumference:</strong> {product.scrotal_circumference} cm</p>}
              {product.docility && <p><strong>Docility:</strong> {product.docility}</p>}
              {product.milk_yield && <p><strong>Milk Yield:</strong> {product.milk_yield} lbs</p>}
              {product.calving_interval && <p><strong>Calving Interval:</strong> {product.calving_interval} days</p>}
              {product.gestation_length && <p><strong>Gestation Length:</strong> {product.gestation_length} days</p>}
            </div>
          </div>

          <div className="grid-item bShadow">
            <h2>Additional Information</h2>
            {product.color && <p><strong>Color:</strong> {product.color}</p>}
            {product.awards && <p><strong>Awards:</strong> {product.awards}</p>}
            {product.horn_status && <p><strong>Horn Status:</strong> {product.horn_status}</p>}
            {product.f11 && <p><strong>F11:</strong> {product.f11}</p>}
            {product.f13 && <p><strong>F13:</strong> {product.f13}</p>}
            {product.chs && <p><strong>CHS:</strong> {product.chs}</p>}
            {product.band3 && <p><strong>Band3:</strong> {product.band3}</p>}
            {product.makeup && <p><strong>Makeup:</strong> {product.makeup}</p>}
          </div>
        </div>

        <div className="product-footer">
          <button onClick={handleMessageSeller} className="action-button">
            Message Seller
          </button>
          <button onClick={() => navigate(`/seller-reviews/${product.user_id}`)} className="action-button">
            Seller Info + Reviews
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
