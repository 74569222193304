

import React, { useState } from 'react';
import { supabase } from './supabaseClient';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError('');

    const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: 'http://localhost:3000/reset-password', // Correct redirect URL
    });

    if (error) {
      setError(error.message);
    } else {
      setMessage('Check your email for the reset password confirmation.');
      
      // Automatically close or redirect after 30 seconds
      setTimeout(() => {
        navigate('/'); // Redirect to the home page after 30 seconds
      }, 30000); // 30000 ms = 30 seconds
    }
  };

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className="forgot-password-container">
      <h1>Forgot Password</h1>
      <form onSubmit={handleForgotPassword}>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        {error && <p className="error">{error}</p>}
        {message && <p className="message">{message}</p>} {/* Show message */}
        <button type="submit" className="submit-button">Send Reset Link</button>
      </form>
      <button onClick={handleGoBack} className="back-button">Back to Home</button>
    </div>
  );
};

export default ForgotPassword;


// import React, { useState } from 'react';
// import { supabase } from './supabaseClient';
// import './ForgotPassword.css';

// const ForgotPassword = () => {
//   const [email, setEmail] = useState('');
//   const [message, setMessage] = useState('');
//   const [error, setError] = useState(null);

//   const handleInputChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handlePasswordReset = async (e) => {
//     e.preventDefault();
//     setError(null);
//     setMessage('');

//     try {
//       const { error } = await supabase.auth.resetPasswordForEmail(email);

//       if (error) {
//         setError(error.message);
//       } else {
//         setMessage('Password reset email has been sent. Please check your inbox. ');
//       }
//     } catch (err) {
//       console.error("Error resetting password:", err);
//       setError('An error occurred. Please try again.');
//     }
//   };

//   return (
//     <div className="forgot-password-container">
//       <h1>Forgot Password</h1>
//       <form onSubmit={handlePasswordReset}>
//         <div className="form-group">
//           <label>Email</label>
//           <input 
//             type="email"
//             name="email"
//             value={email}
//             onChange={handleInputChange}
//             required
//           />
//         </div>
//         {error && <p className="error">{error}</p>}
//         {message && <p className="message">{message}</p>}
//         <button type="submit" className="submit-button">Reset Password</button>
//       </form>
//     </div>
//   );
// };

// export default ForgotPassword;
