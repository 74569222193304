import React, { useState, useEffect } from 'react';
import './AddProducts.css';
import ProductForm from './ProductForm';
import { supabase } from './supabaseClient';

const AddProducts = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Hardcoded user ID for demo purposes
  // const userId = 'b8bc084a-4ebe-4621-9502-6f646e537863';
  
  const userId = localStorage.getItem('userId'); // Retrieve user ID from localStorage

  // Fetch sires and dams from Supabase for this specific user
  useEffect(() => {
    const fetchProducts = async () => {
      // Fetch sires for the specific user
      const { data: siresData, error: siresError } = await supabase
        .from('sires')
        .select('*')
        .eq('user_id', userId);

      // Fetch dams for the specific user
      const { data: damsData, error: damsError } = await supabase
        .from('dams')
        .select('*')
        .eq('user_id', userId);

      if (siresError || damsError) {
        console.error('Error fetching products:', siresError || damsError);
      } else {
        // Add productType to each item
        const siresWithProductType = (siresData || []).map(sire => ({ ...sire, productType: 'sires' }));
        const damsWithProductType = (damsData || []).map(dam => ({ ...dam, productType: 'dams' }));

        // Combine sires and dams into a single array
        const combinedProducts = [...siresWithProductType, ...damsWithProductType];
        setProducts(combinedProducts);
      }
    };

    fetchProducts();
  }, [userId]);

  const handleAddNewProduct = () => {
    const newProduct = {
      id: Date.now(), // Unique ID for the product
      productType: '',
      bull_name: '',
      breed: '',
      price: '',
      registration_no: '',
      born: '',
      breed_association: '',
      breed_association_website: '',
      grade: '',
      stage_of_development: '',
      milk_yield: '',
      calving_interval: '',
      calving_ease_dir: '',
      calving_ease_dtrs: '',
      gestation_length: '',
      birth_weight: '',
      day_200_weight: '',
      day_400_weight: '',
      day_600_weight: '',
      maternal_cow_weight: '',
      day_200_milk: '',
      scrotal_size: '',
      carcase_weight: '',
      eye_muscle_area: '',
      rib_fat: '',
      retail_beef_yield: '',
      imf: '',
      awards: '',
      verified: false,
      imgSrc: '',
      picture_url: '',
    };
    setSelectedProduct(newProduct);
  };

  const handleFormSubmit = (newProduct) => {
    setProducts([...products, newProduct]);
    setSelectedProduct(null); // Close the form after saving
  };

  const handleDeleteProduct = async (productId, productType) => {
    if (!productType) {
      console.error('Error: productType is not defined.');
      return;
    }

    const confirmDelete = window.confirm("Are you sure you want to delete this product?");
    if (!confirmDelete) return;

    try {
      const { error } = await supabase
        .from(productType)
        .delete()
        .eq('id', productId)
        .eq('user_id', userId);

      if (error) {
        console.error('Error deleting product:', error);
      } else {
        // Remove the deleted product from the local state
        setProducts(products.filter(product => product.id !== productId));
        console.log('Product deleted successfully');
      }
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  return (
    <div className="add-products-container">
      <h1>Add Products</h1>
      <div className="add-products-grid">
        {products.map(product => (
          <div key={product.id} className="product-card">
            <button
              className="delete-button"
              onClick={() => handleDeleteProduct(product.id, product.productType)}
            >
              X
            </button>
            <div onClick={() => setSelectedProduct(product)}>
              <img src={product.picture_url || 'https://via.placeholder.com/100'} alt={product.bull_name || 'New Product'} className="product-image" />
              <p>{product.bull_name || 'Unnamed Product'}</p>
            </div>
          </div>
        ))}
        <div className="product-card add-new" onClick={handleAddNewProduct}>
          <div className="add-new-icon">+</div>
          <p>Add New</p>
        </div>
      </div>
      {selectedProduct && (
        <ProductForm product={selectedProduct} onSubmit={handleFormSubmit} onClose={() => setSelectedProduct(null)} />
      )}
    </div>
  );
};

export default AddProducts;
